body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.logo {
  max-height: 200px;
  max-width: 960px;
  height:100%;
  width: 100%;
  box-sizing: border-box;
}

.home-art {
  max-height: 600px;
  max-width: 900px;
  height:100%;
  width: 100%;
  box-sizing: border-box;
}

.artist-statement-text {
  max-width: 900px;
  height:100%;
  width: 100%;
  padding:16px;
  box-sizing: border-box;
}

.footer{
  display:flex;
  justify-content: space-between;
  max-width: 992px;
  width:100%;
  margin: 3px;
}

.footer img{
  width: 30px;
  height: 30px;
  margin: 3px;
}

body{
  background-color: #ffcfd1;
}

.gallery {
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction: column;
  border-color: black;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  width:100%;
  max-width: 1600px;
  height:100%;
  background-color: #fff;
  padding-top: 16px;
}

.Navbar__menu {
  list-style: none;
  display: grid;
  z-index:1000;
  max-width: 464px;
  padding:0;
  margin-top: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 550px) {
  .Navbar__menu {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
.Navbar__menu li{
}

.drop-down {
  position:absolute;
}


li {
  transition: all linear 0.1s;
}

.dropdown-menu {
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
  transition: all linear 0.3s;
}
.menu a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.home-page {
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction: column;
  border-color: black;
  border-width: 0px;
  border-style: solid;
  box-sizing: border-box;
  max-width: 992px;
  padding:16px;
  width:100%;
  background-color: #fff;
}

.about-inline-art{
  max-width: 150px;
  float: left;
  margin-right: 16px;
  margin-top: 4px;
}

.content {
  width:100%;
  align-items:center;
  display:flex;
  flex-direction:column;
  padding:16px;
  box-sizing: border-box;
}

.about-article {
  max-width: 900px;
  width: 100%;
  padding:16px;
  box-sizing: border-box;
}

.about-text {
  line-height: 1.5;
  margin: 0px;
  text-indent: 32px;
  @media screen and (max-width: 550px) {
    text-indent: 0px;
    text-align: center;
  }
}

.about-gallery-container{
  display: flex;
  margin-bottom: 16px;
}

.about-gallery-img {
  max-width: 100%;
}

.socials-container img{
  width: 40px;
  height: 40px;
  margin: 3px;
}

.social {
  display: flex;
  justify-content: center;
}

.contact-container {
  width:100%;
  max-width: 900px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 550px) {
    flex-direction: column-reverse;
  }
}

.contact-right-container{
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width:50%;
  @media screen and (max-width: 550px) {
    width:100%;
    align-items: center;
  }
}
.contact-right-container h1{
  margin:8px 8px 8px 8px;
  font-size: 24px;
  text-align: right;
  @media screen and (max-width: 550px) {
    text-align: center;
  }
}

.contact-left-container p{
  margin:8px 0px 0px 0px;
}
.contact-art{
  max-width: 100%;
  max-height: 200px;
  margin-top: 8px;
}

.contact-left-container{
  width:50%;
  display:flex;
  justify-content: center;
  @media screen and (max-width: 550px) {
    width:100%;
    align-items: center;
  }
}
.menu-button{
  width: 100px;
  background-color: #ffcfd1;
  border:none;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 20px;
  font-family: serif;
}

.menu{
  padding-top:8px;
}

.email-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.email-form textarea{
  flex: 1 1 auto;
}

.email-form button{
  margin-top: 16px;
}

.opened-image-button{
  margin:0;
  padding:0;
  width: 10%;
  aspect-ratio: 1;
  position: fixed;
  font-size: 50px;
  line-height: 100%;
  @media screen and (max-width: 550px) {
    font-size: 25px;
  }
}


